.fa-eye {
  color: forestgreen;
}

.fa-eye-slash {
  color: orangered;
}

.admin-table-header {
  border-bottom: black 2px solid;
  font-weight: bolder;
}

tr:nth-child(even) {background-color: #f9f9f9;}

form > button.btn-primary,
form div.form-group > button.btn-primary {
  background-color: black;
}

form > button.btn-primary:hover,
form div.form-group > button.btn-primary:hover {
  color: #fff;
  background-color: #2B2B2B;
}

.custom-file-label {
  color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.selected-mi {
  border: 3px solid #3D9970;
}

.img-category {
  padding: 15px 0;
}

.top-right-icon {
  position: absolute;
  right: 5%;
}

.form-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.img-resolution {
  position: absolute;
  bottom: -25px;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.pair-i {
  position: absolute;
  top: 100px;
  right: 0;
  z-index: 10;

  color: #f9f9f9;
  background-color: #222222;
}

.pair-i:hover {
  color: greenyellow;
}

.pair-i-paired {
  color: greenyellow;
}

.pair-i-paired:hover {
  color: red !important;
}

.is-paired-right {
  margin-left: -20px;
  margin-right: 5px;
}

.is-paired-left {
  border-right: greenyellow 3px solid;
  margin-left: 5px;
}

.delete-i,
.new-i,
.order-left-i,
.order-right-i {
  position: absolute;
  bottom: 0;
  font-size: 18px;
  height: 30px;
  z-index: 9;
}

.delete-i,
.new-i {
  right: 0;
  top: 1%;
  left: 85%;
}

.order-left-i,
.order-right-i {
  top: 100%;
}

.order-left-i {
  right: 85%;
  left: 5%;
}

.order-right-i {
  left: 85%;
  right: 5%;
}

.delete-i {
  color: red;
}

.new-i {
  top: 3%;
  color: greenyellow;
}

.btn-submit {
  margin-top: 5px;
}

.table {
  text-align: center;
}

.img-admin {
  height: 200px;
  width: auto;
}

.vl {
  border-left: 3px dotted #333D51;
  height: 800px;
}